/* Country List, Vue Component */
<template>
  <v-card id="country-list">
    <v-card-title>Countries</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'country-create' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Country</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">

        <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mr-4"
        ></v-text-field>


      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

    <template #[`item.Name`]="{item}">
          <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'country-view', params: { id: item.Id } }">
      {{ item.Name }}
    </router-link>
    </template>

    <template #[`item.CanManifest`]="{item}">
        <v-icon size="18" :color="booleanIcons(item.CanManifest).variant">{{ booleanIcons(item.CanManifest).icon }}</v-icon>
    </template>


      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <ActionsDropdown :item="item" :actionOptions="actionOptions" />
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import ActionsDropdown from '@/views/helpers/ActionsDropdown'


export default {
  components: {
    ActionsDropdown,

  },
  setup() {

  // define cols
  const tableColumns = [
    { text: 'Name', value: 'Name' },
		{ text: 'Code', value: 'Code' },
    { text: 'Can Manifest', value: 'CanManifest' },
		// { text: 'Postcode Validation', value: 'PostcodeValidation' },
    {
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  // threee params for fetching
  const search = ref({});
  const filter = ref({});
  const options = ref({
    sortBy: ['Name'],
    sortDesc: [false],
    itemsPerPage: 20
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.Countries || []);
  const totalItems = computed(() => store.state.app.CountriesTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // data for filter fields
  const filterItems = ref({

  });

  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchCountries', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });


    const booleanIcons = status => {
      if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
      return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
    }

    const actionOptions = [
      { title: 'View', icon: mdiEyeOutline, target: 'country-view' },
      { title: 'Edit', icon: mdiPencilOutline, target: 'country-edit' }
    ]

    return {
      booleanIcons,
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      actionOptions,
      footerProps,

      Validators,
      Display,
      icons: {
        mdiPlus,
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
